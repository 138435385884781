<template>
  <HomePage />
</template>

<script>
  import HomePage from './pages/HomePage.vue'


export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>

</style>
