<template>
    <div class="about-me-copy-wrapper">
      <div class="about-me-copy">
      <h2>Skills</h2>
      <div class="skills-list-wrapper">
        <div class="front-end-skills-list">
        <h3>Front End</h3>
      <ul >
        <li>HTML</li>
        <li>CSS</li>
        <li>JavaScript</li>
        <li>React</li>
        <li>Vue</li>
      </ul>
        </div>
        <div class="back-end-skills-list">
        <h3>Back End</h3>
      <ul>
        <li>Express</li>
        <li>MongoDB</li>
        <li>Mongoose</li>
        <li>PostgreSQL</li>
        <li>Sequelize</li>
        <li>Python</li>
        <li>Django</li>
        </ul>
        </div>
      </div>
      <h3>About</h3>
      <p>I have walked many paths and one thing that has always remained constant is my love of creation. Whether I am writing a song, building a house, or developing an app, nothing is more satisfying than stepping back and seeing what I have helped create.		
      </p>
      <p>I've always been fascinated by technology, puzzles, and music.  This is probably why I fell in love with video games at such a young age, because they are the amalgamation of all three.</p>
      <p>My professional career started with audio engineering.  I have played, tracked, and mixed dozens of albums, and still do from time to time.  I have also been fortunate enough to tour across the world.</p>  
      <p>Music: check.</p>
      <p>From there, I went into construction management.  My family has been involved in masonry for generations.  My last name in italian actually means pick-axe.</p>
      <p>During this time, I honed my problem solving, planning, and leadership skills.</p>
      <p>Puzzles:  check.</p>
      <p>Now I embark on a new chapter, software engineering.  I believe that all of these industries are connected by a common thread, creation.  They are just building using different materials.  The core concept remains the same.  So now it's time to check off that last checkbox.</p>
      <p>Technology:  check.</p>
      </div>
    </div>
</template>

<script>

export default {
  name: 'AboutMe'
}
</script>